import React from "react"

const BreadcrumbSeparator = ({ children, ...props }) => {
  return (
    <li className="breadcrumb__separator" {...props}>
      {children}
    </li>
  )
}

export const Breadcrumb = ({ separator = "/", ...props }) => {
  let children = React.Children.toArray(props.children)
  const lastIndex = children.length - 1
  children = children
    .map((child, index) => (
      <li className="breadcrumb__list-item" key={`breadcrumb_item${index}`}>
        {child}
      </li>
    ))
    .reduce((acc, child, index) => {
      const notLast = index < lastIndex
      if (notLast) {
        acc.push(
          child,
          <BreadcrumbSeparator key={`breadcrumb_sep${index}`}>
            {separator}
          </BreadcrumbSeparator>
        )
      } else {
        acc.push(child)
      }
      return acc
    }, [])

  return <ol className="breadcrumb">{children}</ol>
}
