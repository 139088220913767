import React from 'react'
import { bgColorToClassName } from '../utils/bgColorToClassName'
import UniversalLink from './UniversalLink'
export default function Button({small, buttonStyle, children, to}) {
  const buttonMod = bgColorToClassName(buttonStyle)
  return (

      <UniversalLink to={to} className={`btn btn--${buttonMod} ${small ? "btn--small" :""}`}>
        {children}
      </UniversalLink>

  )
}
