import { DialogContent, DialogOverlay } from "@reach/dialog";
import "@reach/dialog/styles.css";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Layout from "../components/Layout";
import PageTitleSection from "../components/pagebuilder-parts/PageTitleSection";
import SEO from "../components/SEO";
import useWindowDimensions from "../hooks/useWindowDimensions";
import Button from "../components/Button";


const Gallery = ({data, location}) => {
  const {width: browserWidth} = useWindowDimensions()
  const {allWpGalleryItem: {nodes}} = data
  const [showLightbox, setShowLightbox] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  // create react-tabs tablist
  let tabListTabs = []
  tabListTabs.push("ALL")
  nodes.forEach(node => {
    const {acfPhotoGallery: {category}} = node

    if(!tabListTabs.find(cat => cat === category)) {
      tabListTabs.push(category)
    }
  })
  

  // handle image click
  const toggleLightbox = imgData => {
    if(!browserWidth > 600) {
      return
    }
    setShowLightbox(true)
    setSelectedImage(imgData)
  }
  return (
    <Layout>
      <PageTitleSection data={{title: 'Carport Ideas Gallery'}} location={location} />
      <SEO title={"Carport Photo Gallery"} description="If you need some inspiration for your design, check out some photos of previous jobs we've done." />
      <div className="row u-margin-bottom-sm">
        <div className="col-1-of-1">
          <h1 className="heading-secondary u-center-text u-margin-top-md">
            Check out some of our past work
          </h1>
        </div>
      </div>
      <section className="wrapper photo-gallery">
        <Tabs selectedTabClassName="photo-gallery__tablist-item--selected" className="photo-gallery__tabs">
          <TabList className="photo-gallery__tablist">
            {tabListTabs.map((tab) => {
              const id = tab.split(" ").join("");
              return (
                <Tab className="photo-gallery__tablist-item" key={id}>
                  {tab}
                </Tab>
              );
            })}
          </TabList>
          {tabListTabs.map((tab) => {
            const id = tab.split(" ").join("");
            return (
              <TabPanel className="photo-gallery__gallery-items" selectedClassName="photo-gallery__gallery-items--selected" key={id}>
                {nodes
                  .filter((node) => node?.acfPhotoGallery?.category === tab || tab === "ALL") // Filter based on category or show all for "All" tab
                  .map((node) => {
                    const {
                      id,
                      acfPhotoGallery: { type, description, image: { altText, localFile }, configuratorLink },
                    } = node;

                    const imageData = getImage(localFile);

                    return (
                      <div className="gallery-item">
                      <article className="gallery-item__card" key={id}>

                            <GatsbyImage image={imageData} className="gallery-item__card-img" onClick={(e) => toggleLightbox(imageData)}/>
                        <div className="gallery-item__information-block gallery-item__lightbox-toggle" onClick={(e) => toggleLightbox(imageData)}>
                          <p className="gallery-item__information-block__title">{type}</p>
                          <p className="gallery-item__information-block__details">{description}</p>
                        </div>
                      </article>
                      <Button to={configuratorLink} small buttonStyle={"white"}>Customize in 3D Design</Button>
                      </div>
                    );
                  })}
              </TabPanel>
            );
          })}
        </Tabs>
        {showLightbox && browserWidth > 600 && (
          <DialogOverlay
            allowFullScreen={true}
            style={{zIndex:`10000`,background: `hsla(0, 100%, 100%, .8)`}}
            isOpen={showLightbox}
            onDismiss={() => setShowLightbox(false)}
          >
            <DialogContent style={{boxShadow: "0px 10px 50px hsla(0,0%,0%,0.33", display: `flex`, flexDirection: `column`}} aria-label="Gallery Image Lightbox">
              <button style={{
                alignSelf: `flex-end`,
                marginBottom:`1rem`,
                borderRadius: `20px`,
                border: `none`,
                fontSize: `16px`,
                background: `#ee5753`,
                padding: `.5em 1em`
              }} onClick={() => setShowLightbox(false)}>Close</button>
              <GatsbyImage image={selectedImage} />
            </DialogContent>
          </DialogOverlay>
        )}
      </section>
    </Layout>
  );
}

export default Gallery

export const GALLERY_PAGE_QUERY = graphql`query GALLERY_PAGE_QUERY {
  allWpGalleryItem {
    nodes {
      id
      acfPhotoGallery {
        category
        description
        type
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: FULL_WIDTH)
            }
          }
        }
        configuratorLink
      }
    }
  }
}
`
