import parse from 'html-react-parser'
import React from 'react'
import { FiChevronRight } from 'react-icons/fi'
import { normalizePath } from '../../utils/normalizePath'
import { Breadcrumb } from '../Breadcrumb'
import UniversalLink from '../UniversalLink'

export const PageTitleSection = ({data: {title}, location: {pathname}}) => {
  let slug = ''
  // create array of objects for breadcrumb items
  const items = pathname.split('/').filter(v => v).map(val => ({to: (slug += `/${val}`), label: val.split('-').join(' ')}))


  return (
    <header className="page-title">
      <div className="row">
        <h2 className="page-title__title">{parse(title)}</h2>
        <Breadcrumb separator={<FiChevronRight />}>
          <UniversalLink to="/" className="breadcrumb__link" activeClassName="breadcrumb__active-link">
            Home
          </UniversalLink>
          {items.map(({to, label}) => (
            <UniversalLink className="breadcrumb__link" activeClassName="breadcrumb__active-link" key={to} to={normalizePath(to)}>{parse(label)}</UniversalLink>
          ))}
        </Breadcrumb>
      </div>
    </header>
  )
}

export default PageTitleSection